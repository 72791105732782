import React, { Component } from 'react';
import AsideProvider from '../asideProvider'
import ContainProvider from '../containProvider'
import FavoriteCheck from '../favoriteCheck'
import './providers.scss';
import { connect } from 'react-redux';
import { setCompanyLogo } from '../../redux/actions';


class Providers extends Component {
    static displayName = Providers.name;
    constructor(props) {
        super(props)
        this.state = {
            model: "",
            isAll: true
        }
    }

    componentWillMount() {
        this.props.setCompanyLogo("");
        this.providerModel();
    }

    providerModel = () => {
        fetch("/api/Products/Index" + this.props.providerId, { method: 'GET' })
            .then(response => response.json())
            .then(result => {
                if (result.Status) {
                    this.setState({ model: result.Model });
                    this.props.setCompanyLogo(result.Model.UrlLogoCompany);
                }
            })
            .catch(error => console.log('error', error));
    }

    toggleCategories = (isAll) => {
        this.setState({ isAll: isAll });
    }

    render() {
        const style = {
            bgProviders: { background: this.state.model.UrlHeader }
        }

        return (
            <div className="wrapper_providers">
                <div className="bg_providers" style={style.bgProviders}></div>
                <FavoriteCheck />

                <div className="box_contain_providers">
                    <AsideProvider urlImage={this.state.model.UrlImageVideo} urlVideo={this.state.model.UrlVideo} toggleCategories={this.toggleCategories} isAll={this.state.isAll} />
                    <ContainProvider {...this.state.model} isAll={this.state.isAll} urlImage={this.state.model.UrlImageVideo} urlVideo={this.state.model.UrlVideo} toggleCategories={this.toggleCategories} />
                </div>

            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setCompanyLogo(companyLogo) {
            dispatch(setCompanyLogo(companyLogo));
        }
    }
}

export default connect(null, mapDispatchToProps)(Providers);