import React, { useState } from 'react';
import swal from "sweetalert"
import $ from "jquery"
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toggleBlocking } from '../../../redux/actions';

const ItemPayMethod = ({ payment, onCloseClick }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    let totalItem = 0;
    const [onlyOneClick, setOnlyOneClick] = useState(false)

    const products = () => {
        let cartItems = JSON.parse(localStorage["pruchaseList"])
        totalItem = parseInt(localStorage["pruchaseTotal"]);

        let products = [];
        cartItems.map(provider => {
            provider.Items.map(itemtemp => {
                let item = {
                    ProductId: itemtemp.ProductId,
                    UrlImage: itemtemp.UrlImage,
                    Name: itemtemp.Name,
                    ProductPrice: itemtemp.Price,
                    Cant: itemtemp.Quantity,
                    ProductCode: itemtemp.Code,
                    Description: itemtemp.Descripcion,
                    ProviderId: itemtemp.ProviderId,
                    CountryCode: itemtemp.CountryCode,
                    CampaingCode: itemtemp.CampaingCode,
                    FcPromocionID: itemtemp.FcPromocionID,
                    Reference: JSON.stringify([{
                        ProviderSettingId: itemtemp.SettingProviderId,
                        PropertyValue: itemtemp.Reference,
                        SaveReference: itemtemp.SaveReference
                    }])
                };
                products.push(item);
            })
        });

        return products;
    }

    const postPayment = () => {
        if (!onlyOneClick) {
            setOnlyOneClick(true);
            const model = {
                Products: products(),
                PaymentMethodId: payment.paymentMethodId
            };
            if (totalItem > 0) {
               dispatch(toggleBlocking());
                fetch('/api/PaymentMethod/PaymentMethod', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(model)
                }).then(function (response) {
                    if (response.ok) {
                        if (payment.paymentMethodId != 1 && payment.paymentMethodId != 8) {
                            return response.json()
                        } else {
                            return response.text()
                        }
                    }
                }).then(function (result) {
                    if (payment.paymentMethodId != 1 && payment.paymentMethodId != 8) {
                        if (result.Status) {
                            localStorage["pruchaseTotal"] = 0;
                            localStorage["pruchaseList"] = "[]";
                            setOnlyOneClick(false);
                           dispatch(toggleBlocking());
                            onCloseClick();
                            history.push({
                                pathname: '/payment-method',
                                state: { model: result.Model }
                            })
                        } else {
                            console.log(result.Message);
                            setOnlyOneClick(false);
                           dispatch(toggleBlocking());
                            swal({ text: "Ocurrió un error al abrir método de pago", icon: "error" })
                        }
                    } else {
                        localStorage["pruchaseTotal"] = 0;
                        localStorage["pruchaseList"] = "[]";
                        setOnlyOneClick(false);
                       dispatch(toggleBlocking());
                        onCloseClick();
                        $('body').append(result)
                    }

                }).catch(function (error) {
                    console.log(error);
                    setOnlyOneClick(false);
                   dispatch(toggleBlocking());
                    swal({ text: "Ocurrió un error al abrir método de pago", icon: "error" })
                });
            } else {
                swal({ text: "No hay ningún producto en el carrito de compras", icon: "error" })
                setOnlyOneClick(false);
            }
        }
    }

    return (
        <button
            className="item_paymethod trans"
            onClick={postPayment}>
            <img src={payment.UrlLogo} alt="" />
        </button>
    )
}

export default ItemPayMethod