import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { setModal, toggleBlocking } from '../../redux/actions';

const Register = (props) => {
    const dispatch = useDispatch();
    const company = useSelector(state => state.company);
    const [companies, setCompanies] = useState([]);
    const [registerTypes, setRegisterTypes] = useState([]);
    const [enrollment, setEnrollment] = useState({ show: false, text: "" });
    const { register, handleSubmit } = useForm();

    useEffect(() => {
        companyCatalog();
    }, []);

    const companyCatalog = () => {
        fetch("/api/Account/CompanyCatalog?company=" + company, { method: 'GET' })
            .then(response => response.json())
            .then(result => {
                if (result.Status) {
                    setCompanies(result.Model);
                    if (result.Model.length == 1) onChangeCompany(result.Model[0].Value);
                } else {
                    console.log(result.Message);
                }
            })
            .catch(error => console.log('error', error));
    }

    const onChangeCompany = (company) => {
        fetch("/api/Account/RegisterTypeCatalog?company=" + company, { method: 'GET' })
            .then(response => response.json())
            .then(result => {
                if (result.Status) {
                    setRegisterTypes(result.Model);
                    let registerTypes = result.Model[0];
                    setEnrollment({ show: !(registerTypes.Value == 1 || registerTypes.Value == 8 || registerTypes.Value == 9), text: registerTypes.Text });
                } else {
                    console.log(result.Message);
                    setEnrollment({ show: false, text: "" });
                }
            })
            .catch(error => console.log('error', error));
    }

    const onChangeRegisterType = (e) => {
        const value = e.target.value;
        const text = e.target[e.target.selectedIndex].innerText;
        setEnrollment({ show: !(value == 1 || value == 8 || value == 9), text: text });
    }

    const onSubmit = (data, e) => {
        if (data.IAgree) {
            dispatch(toggleBlocking());
            fetch('/api/Account/Register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(function (response) {
                if (response.ok) {
                    return response.json()
                }
            }).then(function (result) {
                dispatch(toggleBlocking());
                if (result.Status) {
                    e.target.reset();
                    dispatch(setModal());
                    swal({ text: result.Message, icon: "success" });
                } else {
                    swal({ text: result.Message, icon: "error" });
                }
            }).catch(function (error) {
                dispatch(toggleBlocking());
                console.log(error);
                swal({ text: "Error", icon: "error" });
            });
        } else {
            swal({ text: "Debe aceptar los términos y condiciones", icon: "warning" })
        }
    }

    return (
        <div className="content_logeo">
            <div className="pt-3">
                <h5 className="ttls text-center mb-2">Registro nuevo</h5>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label for="name-user">Nombre(s)</label>
                                <input type="text" className="form-control" name="FirstName" id="name-user" required ref={register} />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label for="lastname-user">Apellidos</label>
                                <input type="text" className="form-control" name="Surnames" id="lastname-user" required ref={register} />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label for="email-user"><i className="fas fa-calendar"></i> Fecha de nacimiento</label>
                                <input type="date" className="form-control" id="start" name="Birthday" required ref={register} />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <p className="m-1"><label for="">Sexo</label></p>
                            <div className="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="customRadioInline1" name="Gender" className="custom-control-input" value="M" ref={register} />
                                <label className="custom-control-label" for="customRadioInline1"><small>Masculino</small></label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="customRadioInline2" name="Gender" className="custom-control-input" value="F" ref={register} />
                                <label className="custom-control-label" for="customRadioInline2"><small>Femenino</small></label>
                            </div>
                        </div>

                        <div className={`col-sm-6 ${company == "1Publico" ? 'hide' : ''}`}>
                            <div className="form-group">
                                <label for="company">Empresa</label>
                                <select className="form-control" name="Company" id="company" required ref={register} onChange={(e) => onChangeCompany(e.target.value)} >
                                    {
                                        companies.map(item =>
                                            <option key={item.Value} value={item.Value}>{item.Text}</option>
                                        )
                                    }
                                </select>
                            </div>
                        </div>
                        <div className={`col-sm-6 ${company == "1Publico" ? 'hide' : ''}`}>
                            <div className="form-group">
                                <label for="registerType">Tipo de registro</label>
                                <select className="form-control" name="RegisterType" name="registerType" required ref={register} onChange={onChangeRegisterType} >
                                    {
                                        registerTypes.map(item =>
                                            <option key={item.Value} value={item.Value}>{item.Text}</option>
                                        )
                                    }
                                </select>
                            </div>
                        </div>
                        {
                            enrollment.show ?
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label for="enrollment">{enrollment.text}</label>
                                        <input type="text" className="form-control" name="Enrollment" id="enrollment" defaultValue={props.enrollment} required ref={register} />
                                    </div>
                                </div>
                                :
                                ""
                        }
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label for="email-user">Correo electrónico</label>
                                <input type="email" className="form-control" name="Email" id="email-user" required ref={register} />
                            </div>
                        </div>
                        {/*<div className="col-sm-12">
                            <div className="form-group">
                                <label for="alternativeEmail">Correo alternativo (opcional)</label>
                                <input type="email" className="form-control" name="AlternativeEmail" id="alternativeEmail" ref={register} />
                            </div>
                        </div>*/}
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label for="password_user">Contraseña</label>
                                <input type="password" className="form-control" name="Password" id="password_user" required ref={register} />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label for="password_user">Confirmar contraseña</label>
                                <input type="password" className="form-control" name="ConfirmPassword" id="password_user_confirm" required ref={register} />
                            </div>
                        </div>
                    </div>
                    <p className="text-center">
                        <div className="custom-control custom-checkbox  mb-2">
                            <input type="checkbox" className="custom-control-input" name='IAgree' id="customCheck1" ref={register} />
                            <label className="custom-control-label" for="customCheck1"><small>Acepto términos y condiciones</small></label>
                        </div>
                        <button type="submit" className="btn">Registrarse</button>
                    </p>
                </form>
            </div>
        </div>
    )
}
export default Register;