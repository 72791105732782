import React, { Component } from "react";
import "./oxxoPay.scss";

export class OxxoPayForm extends Component {
    render() {
        return (
            <section className="oxxopay-section">
                <h1>Felicidades</h1>
                <p className="oxxopay-p">Estas muy cerca de tener tus folios.</p>
                <p>Te hemos enviado un email, con las instrucciones para que realices tu pago en Oxxo Pay.</p>
                <img src="./imgs/paymentMethod/oxxo-pay-logo.png" alt="Oxxo Pay" />
                <i class="fas fa-check-circle fa-4x"></i>
                <p><a href="/"> Regresar al inicio</a></p>
            </section>
        )
    }
}