import React from "react";
import { BasePaymentMethod } from "../BasePaymentMethod";
import InputMask from "react-input-mask";
import swal from "sweetalert";
import $ from "jquery";
import "./payUCard.scss";

export class PayUCardForm extends BasePaymentMethod {

    constructor(props) {
        super(props)
        this.state = {
            holderEmail: this.props.HolderEmail,
            holderAddress: this.props.HolderAddress,
            city: this.props.City,
            country: this.props.Country,
            document: this.props.Document,
            first_name: this.props.HolderName,
            last_name: this.props.HolderLastName,
            card_number: "",
            card_number_hide: "",
            card_number_display: "",
            card_number_show: false,
            expiration_month: "",
            expiration_year: "",
            credit_card_cv: "",
            formValidators: {
                holderEmail: null,
                holderAddress: null,
                city: null,
                country: null,
                document: null,
                first_name: null,
                last_name: null,
                card_number: null,
                expiration_month: null,
                expiration_year: null,
                credit_card_cv: null
            }
        }

        this.POS = "";
    }

    async componentWillMount() {
        const script = document.createElement("script");
        script.src = "https://js.paymentsos.com/v2/latest/token.min.js";
        script.async = true;
        document.head.appendChild(script);

        window.setTimeout(function () {
            this.POS = window.Paymentsos
            this.POS.setEnvironment(this.props.Environment) // Set your environment
            this.POS.setPublicKey(this.props.PublicKey) // Set your public key
        }.bind(this), 2000); // 2 seconds 

        let isDefault = this.props.LstCountry.find(function (item) {
            return item.IsDefault
        })

        this.setState({ country: isDefault.Value });
    }

    handleChange = async (event) => {
        const name = event.target.name
        const value = event.target.value
        this.setState({ [name]: value })
        await this.validateInputs(event.target)
    }

    validateInputs = async (input) => {
        const name = input.name
        const value = input.value
        const formValidators = this.state.formValidators;
        switch (name) {
            case "holderEmail":
            case "holderAddress":
            case "city":
            case "country":
            case "document":
            case "first_name":
            case "last_name":
            case "card_number":
            case "credit_card_cv":
                formValidators[name] = value.length <= 0 ? "Éste campo es obligatorio" : ""
                break;
            case "expiration_year":
                formValidators[name] = value.length != 2 ? "El año debe contener 2 dígitos" : ""
                break;
            case "expiration_month":
                formValidators[name] = value.length != 2 ? "El mes debe contener 2 dígitos" : ""
                break;
        }
        await this.setState({ formValidators })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const inputs = $(".payment-input-client ,.payment-card, .payment-date, .payment-code")

        const validationInputs = Object.keys(this.state.formValidators)
        for (let input of inputs) {
            if (validationInputs.includes(input.name)) {
                this.validateInputs(input);
            }
        }

        let failedInputs;
        failedInputs = Object.values(this.state.formValidators).find(value => {
            return value == null || value.length > 0
        })

        if (failedInputs === undefined) {
            // create token
            this.POS.tokenize({
                "token_type": "credit_card",
                "credit_card_credit_card_cv": this.state.credit_card_cv,
                "card_number": this.state.card_number,
                "expiration_date": this.state.expiration_month + "/" + this.state.expiration_year,
                "holder_name": this.state.first_name + " " + this.state.last_name
            }, (result) => {
                let resultObj = JSON.parse(result);
                if (resultObj.pass_luhn_validation) {
                    resultObj.orderId = this.props.OrderId
                    resultObj.first_name = this.state.first_name
                    resultObj.last_name = this.state.last_name
                    resultObj.email = this.state.holderEmail
                    resultObj.address = this.state.holderAddress
                    resultObj.city = this.state.city
                    resultObj.country = this.state.country
                    resultObj.document = this.state.document
                    resultObj.cvv = this.state.credit_card_cv
                    fetch('/api/PaymentMethod/PayUCardProcess',
                        {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify(resultObj)
                        }).then(function (response) {
                            if (response.ok) {
                                return response.text()
                            }
                        }).then(function (data) {
                            $('body').append(data)
                        }).catch(function (error) {
                            // Process failure here
                            swal({ text: "Error", icon: "error" })
                        })
                } else {
                    swal({ text: "El número de la tarjeta no es válido.", icon: "error" })
                }
            })
        }
    }

    handleChangeCard = async (e) => {
        let valueDisplay = this.getValueDisplay(e, 5, 14)
        this.handleChange(e);
        this.setState({ card_number_hide: valueDisplay })
        this.setState({ card_number_display: this.state.card_number_show ? e.target.value : valueDisplay })
    }

    handleChangeCheck = (e) => {
        this.setState({ [e.target.name]: e.target.checked })
        this.setState({ card_number_display: e.target.checked ? this.state.card_number : this.state.card_number_hide })
    }

    render() {
        const { formValidators } = this.state;
        return (
            <form onSubmit={this.handleSubmit} id="formulario-pago">
                <section className="wrapper-payu-card">
                    <section className="data-side">
                        <section className="payment-content">
                            <h1>Datos de compra</h1>
                            <p className="payment-p">Número de pedido</p>
                            <p className="data-p">{this.props.OrderNumber}</p>
                            <p className="payment-p">Nombre</p>
                            <input type="text"
                                className={`payment-input-client ${formValidators.first_name && 'error'}`} id="first_name"
                                name="first_name"
                                value={this.state.first_name}
                                onChange={this.handleChange}
                                autoComplete="off"
                            />
                            {(formValidators.first_name) && (
                                <small>{formValidators.first_name}</small>
                            )}
                            <p className="payment-p">Apellido</p>
                            <input type="text"
                                className={`payment-input-client ${formValidators.last_name && 'error'}`} id="last_name"
                                name="last_name"
                                value={this.state.last_name}
                                onChange={this.handleChange}
                                autoComplete="off"
                            />
                            {(formValidators.last_name) && (
                                <small>{formValidators.last_name}</small>
                            )}
                            <p className="payment-p">Número de tarjeta</p>
                            <InputMask
                                className={`payment-card ${formValidators.card_number && 'error'}`}
                                placeholder="XXXXXXXXXXXXXXXX"
                                mask="9999999999999999"
                                name="card_number"
                                size="20"
                                onChange={this.handleChangeCard}
                                maskChar=""
                                autoComplete="off"
                            />
                            <InputMask
                                className="payment-card-display"
                                name="card_number_display"
                                value={this.state.card_number_display}
                                autoComplete="off" />
                            {(formValidators.card_number) && (<small>{formValidators.card_number}</small>)}
                            <input type="checkbox" name="card_number_show" id="card_number_Show" onChange={this.handleChangeCheck} /><label htmlFor="card_number_Show" className="cardShow-label"> Mostrar tarjeta</label>
                            <p className="payment-p">Fecha de expiración</p>
                            <InputMask
                                className={`payment-date ${formValidators.expiration_month && 'error'}`}
                                placeholder="MM"
                                mask="99"
                                name="expiration_month"
                                maskChar=""
                                onChange={this.handleChange}
                                autoComplete="off" />
                            <InputMask
                                className={`payment-date ${formValidators.expiration_year && 'error'}`}
                                placeholder="AA"
                                mask="99"
                                name="expiration_year"
                                maskChar=""
                                onChange={this.handleChange}
                                autoComplete="off" />
                            {(formValidators.expiration_month) && (<small>{formValidators.expiration_month}</small>)}
                            {(formValidators.expiration_year) && (<small>{formValidators.expiration_year}</small>)}
                            <p className="payment-p">Código de seguridad</p>
                            <InputMask
                                className={`payment-code ${formValidators.credit_card_cv && 'error'}`}
                                mask="999"
                                name="credit_card_cv"
                                maskChar=""
                                onChange={this.handleChange}
                                autoComplete="off" />
                            <span><i className="fa fa-credit-card card-i"></i></span>
                            <span className="card-text">3 digitos al reverso de la trajeta</span>
                            {(formValidators.credit_card_cv) && (<small>{formValidators.credit_card_cv}</small>)}
                            <button type="submit">Pagar <strong>{this.props.currency}{(this.props.Amount).toLocaleString(this.props.cultureCode, { minimumFractionDigits: 0, maximumFractionDigits: 0 })} {this.props.isoCurrency}</strong></button>
                        </section>
                        <section className="cancel-button-content">
                            <button type="button" onClick={this.cancel}>Cancelar</button>
                            <figure className="payment-img-content">
                                <img src="./imgs/paymentMethod/visa-and-mastercard-logo.png" alt="VisaMastercard" />
                            </figure>
                        </section>
                    </section>
                    <section className="payment-side">
                        <section className="data-content">
                            <h1>Datos de usuario</h1>
                            <p className="payment-p">Dirección</p>
                            <textarea
                                className={`payment-input-client ${formValidators.holderAddress && 'error'}`} id="holderAddress"
                                name="holderAddress"
                                value={this.state.holderAddress}
                                onChange={this.handleChange}
                                autoComplete="off"
                            ></textarea>
                            {(formValidators.holderAddress) && (
                                <small>{formValidators.holderAddress}</small>
                            )}
                            <p className="payment-p">País</p>
                            <select
                                className={`payment-input-client ${formValidators.country && 'error'}`} id="country"
                                value={this.state.country}
                                id="country"
                                name="country"
                                onChange={this.handleChange}>
                                {
                                    this.props.LstCountry.map(item =>
                                        <option key={item.Value} value={item.Value}>{item.Text}</option>)
                                }
                            </select>
                            {(formValidators.country) && (
                                <small>{formValidators.country}</small>
                            )}
                            <p className="payment-p">Ciudad</p>
                            <input type="text"
                                className={`payment-input-client ${formValidators.city && 'error'}`} id="city"
                                name="city"
                                value={this.state.city}
                                onChange={this.handleChange}
                                autoComplete="off"
                            />
                            {(formValidators.city) && (
                                <small>{formValidators.city}</small>
                            )}
                            <p className="payment-p">Email</p>
                            <input type="holderEmail"
                                className={`payment-input-client ${formValidators.holderEmail && 'error'}`} id="holderEmail"
                                name="holderEmail"
                                value={this.state.holderEmail}
                                onChange={this.handleChange}
                                autoComplete="off"
                            />
                            {(formValidators.holderEmail) && (
                                <small>{formValidators.holderEmail}</small>
                            )}
                            <p className="payment-p">Número de documento</p>
                            <input type="text"
                                className={`payment-input-client ${formValidators.document && 'error'}`} id="document"
                                name="document"
                                value={this.state.document}
                                onChange={this.handleChange}
                                autoComplete="off"
                            />
                            {(formValidators.document) && (
                                <small>{formValidators.document}</small>
                            )}
                        </section>
                    </section>
                    <aside className="information-side">
                        <section className="information-content">
                            <label className="information-title">Su información está protegida</label>
                            <p className="information-text">Empleamos los más altos estándares de seguridad (SSL, 3D Secure) para proteger su información personal y la de tu tarjeta. Revise al pie de su página el símbolo del condado (SSL) que garantiza la autenticidad de la página.</p>
                        </section>
                    </aside>
                </section>
            </form>)
    }
}