import React from 'react'
import ItemProduct from '../itemProduct'
import AsideProvider from '../asideProvider'
import './containProvider.scss'
import $ from 'jquery'
import swal from "sweetalert"

const ContainProvider = (props) => {
    const outstandingProducts = props.OutstandingProducts;
    const products = props.CompanyProducts;

    const style = {
        show: { display: 'flex' },
        hide: { display: 'none' }
    }
    
    const addPruchaseItem = (itemData) => {
        let cartItems = JSON.parse(localStorage["pruchaseList"])
        let totalItems = parseFloat(localStorage["pruchaseTotal"]);

        let item = {
            ProductId: itemData.ProductId,
            Name: itemData.Name,
            Price: itemData.ProductPrice,
            Quantity: 1,
            Code: itemData.ProductCode,
            UrlImage: props.UrlLogoClrs,
            Descripcion: itemData.Description,
            ProviderId: itemData.proveedorId,
            CountryCode: itemData.CountryCode,
            CampaingCode: itemData.CampaingCode,
            FcPromocionID: itemData.FcPromocionID,
            Total: itemData.ProductPrice,
            SettingProviderId: props.SettingProviderId,
            SettingProviderName: props.SettingProviderName,
            Reference: itemData.Reference,
            SaveReference: itemData.SaveReference
        }

        let foundProvider = cartItems.find(p => p.ProviderId == props.ProviderId);
        if (foundProvider != undefined) {
            let isNew = true;
            if (item.Reference == undefined) {
                let foundProduct = foundProvider.Items.find(p => p.ProductId == item.ProductId);
                if (foundProduct != undefined) {
                    foundProduct.Quantity += item.Quantity;
                    foundProduct.Total = foundProduct.Quantity * item.Price
                    isNew = false;
                }
            } else {
                let foundProduct = foundProvider.Items.find(p => p.ProductId == item.ProductId && p.Reference == item.Reference);
                if (foundProduct != undefined) {
                    swal({ text: "Esta referencia ya esta en carrito de compras", icon: "error" });
                    return false;
                }
            }

            if (isNew) foundProvider.Items.push(item)

            foundProvider.Total += item.Total;
        } else {
            let itemProvider =
            {
                ProviderId: props.ProviderId,
                ProviderName: props.Name,
                Logo: props.UrlLogoClrs,
                Total: item.Price,
                Items: [item]
            }

            cartItems.push(itemProvider)
            $("#spanActivePurchase").click();
        }

        localStorage["pruchaseTotal"] = totalItems + item.Price
        localStorage["pruchaseList"] = JSON.stringify(cartItems)
    }

    return (
        <div className="contain_providers" >
            <div className="contain_info_provaider">
                <div className="box_info_provaider">
                    <h2 className="ttls_provider">{props.Name}</h2>
                    <p className="info_provider">{props.Info}</p>
                </div>
                <div className="box_logo_provaider">
                    <img src={props.UrlLogo} alt="" />
                </div>
            </div>

            <div className="wrapper_products_provider">
                <div className="wrapper_categ_mobile">
                    <AsideProvider urlImage={props.urlImage} urlVideo={props.UrlVideo} toggleCategories={props.toggleCategories} isAll={props.isAll} />
                </div>
                <h5 className="ttls fw600 mb-4">{props.isAll ? "Todos los productos" : "Lo más vendido"}</h5>
                <div className="content_products_provider row" style={!props.isAll ? style.show : style.hide}>
                    {outstandingProducts != undefined ?
                        outstandingProducts.map(item => {
                            return <ItemProduct {...item} settingProviderId={props.SettingProviderId} settingProviderName={props.SettingProviderName} providerName={props.Name} urlRoot={props.UrlSitioAdmin} addPruchaseItem={addPruchaseItem} urlLogoClrs={props.UrlLogoClrs} />
                        })
                        :
                        ""
                    }
                </div>
                <div className="content_products_provider row" style={props.isAll ? style.show : style.hide}>
                    {products != undefined ?
                        products.map(item => {
                            return <ItemProduct {...item} settingProviderId={props.SettingProviderId} settingProviderName={props.SettingProviderName} providerName={props.Name} urlRoot={props.UrlSitioAdmin} addPruchaseItem={addPruchaseItem} urlLogoClrs={props.UrlLogoClrs} />
                        })
                        :
                        ""
                    }
                </div>
            </div>

        </div >
    )
}

export default ContainProvider;