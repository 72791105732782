import React from "react";
import { BasePaymentMethod } from "../BasePaymentMethod";
import InputMask from 'react-input-mask';
import "./credomaticGT.scss";

export class CredomaticGTForm extends BasePaymentMethod {
    constructor(props) {
        super(props)

        this.state = {
            card: "",
            cardHide: "",
            cardDisplay: "",
            cardShow: false,
            mm: "",
            yy: "",
            ccv: "",
            submitDisabled: true
        };
    }

    valid(card_number, cvv, expiration_year, expiration_month)
    {
        let cardno_american = /^(?:3[47][0-9]{13})$/;
        let cardno_visa = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
        let cardno_master = /^(?:5[1-5][0-9]{14})$/;
        let current_year = new Date().getFullYear().toString().substr(-2);
        let current_month = new Date().getMonth() + 1; //Jan 0, feb 1, ...
        let max_month = 12;
        let min_month = 1
        if (expiration_month > max_month || expiration_month < min_month ||  Number.isNaN(expiration_month)) {return false;}
        if (expiration_year < current_year || Number.isNaN(expiration_year)) {return false;}
        if ((expiration_year == current_year) && (expiration_month < current_month)) {return false;}
        
        if(card_number.match(cardno_american)){
            if (cvv.length == 4){ return true;}
        }
        else if (card_number.match(cardno_visa)){
            if (cvv.length == 3){ return true;}
        }
        else if (card_number.match(cardno_master)){
            if (cvv.length == 3){ return true;}
        }
        return false;
    }

    checkFormStatus(){
        let card_number = document.getElementById("cardt").value.replace(/ /g,'');
        let card_year_exp = parseInt(document.getElementById("yy").value.toString());
        let card_month_exp = parseInt(document.getElementById("mm").value.toString());
        let card_cvv = document.getElementById("ccvt").value;
        var btnPagar = document.getElementById('btnPagar');
        if (this.valid(card_number, card_cvv, card_year_exp, card_month_exp)){
            btnPagar.removeAttribute("disabled");
            btnPagar.className = "payment-button-enabled";
            btnPagar.title = "";
        }
        else {
            btnPagar.disabled = true;
            btnPagar.className = "payment-button-disabled";
            btnPagar.title = "Complete los datos correctamente para poder realizar el pago!";
        }
    }


    changeInput = (e) => {
        this.setState({ [e.target.name]: e.target.value })
        this.checkFormStatus();
    }

    changeInputCard = (e) => {
        let valueDisplay = this.getValueDisplay(e, 6, 17)
        this.setState({ card: e.target.value })
        this.setState({ cardHide: valueDisplay })
        this.setState({ cardDisplay: this.state.cardShow ? e.target.value : valueDisplay })
        this.checkFormStatus();
    }

    changeInputCheck = (e) => {
        this.setState({ [e.target.name]: e.target.checked })
        this.setState({ cardDisplay: e.target.checked ? this.state.card : this.state.cardHide })
    }

    sendData = (e) => {
        e.preventDefault()
        document.getElementById("ccnumber").value = document.getElementById("cardt").value
        document.getElementById("ccexp").value = document.getElementById("mm").value.toString() + document.getElementById("yy").value.toString()
        document.getElementById("cvv").value = document.getElementById("ccvt").value

        document.getElementById("formPagar").submit()
    }

    disabled(){
        return true;
    }


    render() {
        return (
            <div className="wrapper-credomatic-gt">
                <div className="data-side">
                    <div className="data-content">
                        <h1>Datos de compra</h1>
                        <label>Comercio</label>
                        <p className="data-p">Red Voucher</p>
                        <label>Concepto</label>
                        <p className="data-p">Productos Red Voucher</p>
                        <label>Número de pedido</label>
                        <p className="data-p">{this.props.ORDERNUMBER}</p>
                        <label>Monto</label>
                        <p className="amount-p">{this.props.currency}{(this.props.AMOUNT).toLocaleString(this.props.cultureCode, { minimumFractionDigits: 0, maximumFractionDigits: 2 })} {this.props.isoCurrency}</p>
                    </div>
                    <div className="cancel-button-content">
                        <button type="button" onClick={this.cancel}>Cancelar</button>
                    </div>
                </div>
                <div className="payment-side">
                    <div className="payment-content">
                        <form onSubmit={this.sendData}>
                            <h1>Pago con tarjeta</h1>
                            <p>Número de tarjeta</p>
                            <InputMask className="payment-card" placeholder="XXXX XXXX XXXX XXXX" mask="9999 9999 9999 9999" id="cardt" name="card" size="20" onChange={this.changeInputCard} maskChar="" />
                            <InputMask className="payment-card-display" name="cardDisplay" value={this.state.cardDisplay} />
                            <input type="checkbox" name="cardShow" id="cardShow" onChange={this.changeInputCheck} /><label htmlFor="cardShow" className="cardShow-label"> Mostrar tarjeta</label>
                            <p>Fecha de expiración</p>
                            <InputMask className="payment-date" placeholder="MM" mask="99" id="mm" name="mm" maskChar="" onChange={this.changeInput} />
                            <InputMask className="payment-date" placeholder="AA" mask="99" id="yy" name="yy" maskChar="" onChange={this.changeInput} />
                            <p>Código de seguridad</p>
                            <InputMask className="payment-code" mask="9999" id="ccvt" name="ccv" maskChar="" onChange={this.changeInput} />
                            <span><i className="fa fa-credit-card card-i"></i></span>
                            <span className="card-text">3 digitos al reverso de la trajeta</span>
                            <button id="btnPagar" type="submit" disabled={true} className="payment-button-disabled">Pagar <strong>{this.props.currency}{(this.props.AMOUNT).toLocaleString(this.props.cultureCode, { minimumFractionDigits: 0, maximumFractionDigits: 2 })} {this.props.isoCurrency}</strong></button>
                        </form>
                    </div>
                    <div className="payment-img-content">
                        <img src="./imgs/paymentMethod/visa-and-mastercard-logo.png" alt="VisaMastercard" />
                    </div>
                </div>
                <div className="information-side">
                    <div className="information-content">
                        <label className="information-title">Su información está protegida</label>
                        <p className="information-text">Empleamos los más altos estándares de seguridad (SSL, 3D Secure) para proteger su información personal y la de tu tarjeta. Revise al pie de su página el símbolo del condado (SSL) que garantiza la autenticidad de la página.</p>
                    </div>
                </div>


                <form method="Post" id="formPagar" action={this.props.PAYMENTURL}>

                    <input type="text" id="hash" name="hash" value={this.props.HASH} style={{ display: 'none', }} />
                    <input type="text" id="time" name="time" value={this.props.TIME} style={{ display: 'none', }} />
                    <input type="text" id="ccnumber" name="ccnumber" value={this.props.CCNUMBER} style={{ display: 'none', }} />

                    <input type="text" id="ccexp" name="ccexp" value={this.props.CCEXP} style={{ display: 'none', }} />
                    <input type="text" id="amount" name="amount" value={this.props.AMOUNT} style={{ display: 'none', }} />
                    <input type="text" id="type" name="type" value={this.props.TYPE} style={{ display: 'none', }} />

                    <input type="text" id="orderid" name="orderid" value={this.props.ORDERID} style={{ display: 'none', }} />
                    <input type="text" id="key_id" name="key_id" value={this.props.KEY_ID} style={{ display: 'none', }} />
                    <input type="text" id="processor_id" name="processor_id" value={this.props.PROCESSOR_ID} style={{ display: 'none', }} />

                    <input type="text" id="cvv" name="cvv" value={this.props.CVV} style={{ display: 'none', }} />
                    <input type="text" id="address" name="address" value="" style={{ display: 'none', }} />
                    <input type="text" id="redirect" name="redirect" value={this.props.REDIRECT} style={{ display: 'none', }} />

                    <input type="text" id="username" name="username" value="" style={{ display: 'none', }} />

                </form>
            </div>
        )
    }
}
