import React, { Component } from 'react';
import $ from 'jquery';
import TermsAndConditionsMexico from '../components/countryTermsAndConditions/mexico';
import TermsAndConditionsCostaRica from '../components/countryTermsAndConditions/costaRica';
import TermsAndConditionsGuatemala from '../components/countryTermsAndConditions/Guatemala';
import { connect } from 'react-redux';

class TermsAndConditions extends Component {
    static displayName = TermsAndConditions.name;

    componentDidMount() {
        $('html, body').scrollTop(0);
    }

    termsAndConditionsByCountry = () => {
        switch (this.props.countrySetting.cultureCode) {
            case "es-MX":
                return <TermsAndConditionsMexico />;
            case "es-CR":
                return <TermsAndConditionsCostaRica />;
            case "es-GT":
                return <TermsAndConditionsGuatemala />;
        }
    }

    render() {
        const style = {
            bgSections: { background: 'url(./imgs/bglegal.jpg)' }
        }
        return (
            <section className="wrapper_legal ptop">
                <div className="bg_sections" style={style.bgSections}></div>
                <div className="container content_legal">
                    <h2 className="ttls fw600 c_white mb-3">Términos y condiciones</h2>
                    {this.termsAndConditionsByCountry()}
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        countrySetting: state.countrySetting
    }
}

export default connect(mapStateToProps)(TermsAndConditions);