import React, { Component } from "react";
import { connect } from 'react-redux';
import { CredomaticForm } from "../components/formPaymentMethod/credomatic/CredomaticForm"
import { BanorteForm } from "../components/formPaymentMethod/banorte/banorteForm";
import { OxxoPayForm } from "../components/formPaymentMethod/oxxoPay/oxxoPayForm";
import { PayUCardForm } from "../components/formPaymentMethod/payU/payUCardForm"
import { PayUCashForm } from "../components/formPaymentMethod/payU/payUCashForm"
import { CredomaticGTForm } from "../components/formPaymentMethod/credomatic/credomaticGTForm";

class PaymentMethod extends Component {

    switchPaymentMethod = () => {
        const { model } = this.props.location.state;
        let component
        switch (model.PaymentMethodId) {
            // Banorte
            case 2:
                component = (<BanorteForm {...model} {...this.props.countrySetting}></ BanorteForm>)
                break
            // Credomatic
            case 3:
                component = (<CredomaticForm {...model} {...this.props.countrySetting}></CredomaticForm>)
                break
            // Oxxo Pay
            case 4:
                component = (<OxxoPayForm {...model}></OxxoPayForm>)
                break
            // PayU Card
            case 5:
                component = (<PayUCardForm {...model} {...this.props.countrySetting}></ PayUCardForm>)
                break
            // PayU Cash
            case 6:
                component = (<PayUCashForm {...model}></ PayUCashForm>)
                break
            // Credomatic GT
            case 7:
                component = (<CredomaticGTForm {...model} {...this.props.countrySetting}></ CredomaticGTForm>)
                break
        }
        return component;
    }

    render() {
        return (
            <section className="container_payment">
                {this.switchPaymentMethod()}
            </section>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        countrySetting: state.countrySetting
    }
}

export default connect(mapStateToProps)(PaymentMethod);