import React from 'react';
import { createUltimatePagination, ITEM_TYPES } from 'react-ultimate-pagination';


const Page = ({ value, isActive, onClick, isDisabled }) => (
    <button
        className={isActive ? "isActive" : ""}
        onClick={onClick}
        disabled={isDisabled}
    > {value}
    </button>
);

const Ellipsis = ({ onClick, isDisabled }) => (
    <button
        onClick={onClick}
        disabled={isDisabled}
    >...</button>
);

const FirstPageLink = ({ onClick, isDisabled }) => (
    <button
        onClick={onClick}
        disabled={isDisabled}
    ><i class="fas fa-step-backward"></i></button>
);

const PreviousPageLink = ({ onClick, isDisabled }) => (
    <button
        onClick={onClick}
        disabled={isDisabled}
    ><i class="fas fa-chevron-left"></i></button>
);

const NextPageLink = ({ onClick, isDisabled }) => (
    <button
        onClick={onClick}
        disabled={isDisabled}
    ><i class="fas fa-chevron-right"></i></button>
);

const LastPageLink = ({ onClick, isDisabled }) => (
    <button
        onClick={onClick}
        disabled={isDisabled}
    ><i class="fas fa-step-forward"></i></button>
);

const itemTypeToComponent = {
    [ITEM_TYPES.PAGE]: Page,
    [ITEM_TYPES.ELLIPSIS]: Ellipsis,
    [ITEM_TYPES.FIRST_PAGE_LINK]: FirstPageLink,
    [ITEM_TYPES.PREVIOUS_PAGE_LINK]: PreviousPageLink,
    [ITEM_TYPES.NEXT_PAGE_LINK]: NextPageLink,
    [ITEM_TYPES.LAST_PAGE_LINK]: LastPageLink
};

export const PaginatedPage = createUltimatePagination({ itemTypeToComponent });