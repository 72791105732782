import React, { useState, useEffect } from 'react';
import ItemPurchase from '../itemPurchase';
import BoxPayMethod from '../boxPayMethod';
import './asidePurchase.scss';
import swal from "sweetalert";
import { useSelector } from 'react-redux';

const AsidePurchase = ({ activeAside, onCloseClick }) => {
    const countrySetting = useSelector(state => state.countrySetting);

    const [activeAccordion, setStateAccordion] = useState('')
    const [activeChevron, setStateChevron] = useState('')
    const [pruchaseItems, setPurchaseItems] = useState([])
    const [total, setTotal] = useState(0)

    useEffect(() => {
        if (activeAside) {
            setPurchaseItems(JSON.parse(localStorage["pruchaseList"]));
            setTotal(JSON.parse(localStorage["pruchaseTotal"]));
        }
    });

    function AsidePurchaseBox() {
        setStateAccordion(activeAccordion === '' ? 'show' : '')
        setStateChevron(activeChevron === '' ? 'open' : '')
    }

    const deletePurchaseItemDetail = (providerId, productId, reference) => {
        swal({
            title: "¿Estas seguro?",
            text: "¡Desea eliminar este productos del carrito de compras!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    let cartItems = JSON.parse(localStorage["pruchaseList"]);
                    let totalItems = parseFloat(localStorage["pruchaseTotal"]);

                    let foundProvider = cartItems.find(p => p.ProviderId == providerId);
                    if (foundProvider != undefined) {
                        let foundProduct = foundProvider.Items.find(p => p.ProductId == productId && p.Reference == reference);
                        console.log(foundProduct);
                        if (foundProduct != undefined) {
                            foundProvider.Total -= foundProduct.Total
                            totalItems -= foundProduct.Total;
                            if (reference == undefined) foundProvider.Items = foundProvider.Items.filter(p => p.ProductId != productId);
                            else foundProvider.Items = foundProvider.Items.filter(p => p.Reference != reference)

                            if (foundProvider.Total == 0) cartItems = cartItems.filter(p => p.ProviderId != providerId);
                        }
                    }

                    localStorage["pruchaseTotal"] = totalItems;
                    localStorage["pruchaseList"] = JSON.stringify(cartItems)

                    setTotal(totalItems);
                    setPurchaseItems(cartItems);
                }
            });
    }

    const deletePurchaseItem = (providerId, providerName) => {
        swal({
            title: "¿Estas seguro?",
            text: "¡Desea eliminar los productos de " + providerName + " del carrito de compras!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    let cartItems = JSON.parse(localStorage["pruchaseList"]);
                    let totalItems = parseFloat(localStorage["pruchaseTotal"]);

                    let foundProvider = cartItems.find(p => p.ProviderId == providerId);
                    if (foundProvider != undefined) {

                        totalItems -= foundProvider.Total;
                        cartItems = cartItems.filter(p => p.ProviderId != providerId);
                    }

                    localStorage["pruchaseTotal"] = totalItems;
                    localStorage["pruchaseList"] = JSON.stringify(cartItems)

                    setTotal(totalItems);
                    setPurchaseItems(cartItems);
                }
            });
    }

    const onChangeQuantity = (providerId, productId, value) => {
        let val = parseInt(value);
        let cartItems = JSON.parse(localStorage["pruchaseList"]);
        let totalItems = parseFloat(localStorage["pruchaseTotal"]);

        let foundProvider = cartItems.find(p => p.ProviderId == providerId);
        if (foundProvider != undefined) {
            let foundProduct = foundProvider.Items.find(p => p.ProductId == productId);
            if (foundProduct != undefined) {

                foundProvider.Total -= foundProduct.Total;
                totalItems -= foundProduct.Total;

                foundProduct.Quantity = val;
                foundProduct.Total = foundProduct.Quantity * foundProduct.Price;

                foundProvider.Total += foundProduct.Total;
                totalItems += foundProduct.Total;
            }
        }

        localStorage["pruchaseTotal"] = totalItems;
        localStorage["pruchaseList"] = JSON.stringify(cartItems)

        setTotal(totalItems);
        setPurchaseItems(cartItems);
    }

    return <React.Fragment>
        <aside className={`wrapper_aside_purchase trans ${activeAside}`}>
            <div className="box_content_aside">
                <div className="titulares box_flex ">
                    <h5 className="ttls_sections fw600 m-0"><i class="fas fa-shopping-cart"></i> Mis compras</h5>
                    <div className=" btn_closeaside" onClick={onCloseClick}><i className="fas fa-times-circle"></i> <span>Cerrar</span></div>
                </div>
                <div className="content_items">
                    {
                        pruchaseItems.map(item => {
                            return < ItemPurchase {...item} deletePurchaseItem={deletePurchaseItem} deletePurchaseItemDetail={deletePurchaseItemDetail} onChangeQuantity={onChangeQuantity} />
                        })
                    }
                </div>
                <div className="box_footer_aside ttls_sections">
                    <div className="box_total_neto">
                        <div>
                            <h5 className="fw600 c_primary m-0">¡FELICIDADES!</h5>
                            <p className="label fw300">En esta compra ahorraste más del 35% del costo normal.</p>
                        </div>
                        <div className="box_w">
                            <h4 className=" fw700">Total: {countrySetting.currency}{total.toString().toLocaleString(countrySetting.cultureCode, { minimumFractionDigits: 0, maximumFractionDigits: 2 })}</h4>
                        </div>
                    </div>
                    <div className="wrapper_paymethod">
                        <h6 className="ttls_sections"><span>Elige tu método de pago</span></h6>
                        <BoxPayMethod onCloseClick={onCloseClick} />
                        <div className="box_powered_by">
                            <small className="font-italic fw600">Powered by</small>
                            <a target="_blank" href="https://redpay.mx">
                                <img src="./imgs/paymentMethod/red-pay-logo.png" alt="RedPay" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </aside>
        <div className="bg_pleca trans" onClick={onCloseClick}></div>
    </React.Fragment>
}

export default AsidePurchase