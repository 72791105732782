import React from "react";
import swal from "sweetalert";
import { BasePaymentMethod } from "../BasePaymentMethod";
import InputMask from "react-input-mask";
import "./banorte.scss";

export class BanorteForm extends BasePaymentMethod {
    isComponentMounted
    constructor(props) {
        super(props)
        this.state = {
            cardType: '',
            BillTo_firstName: "",
            BillTo_email: "",
            Card_cardType: "",
            Card_accountNumber: "",
            Card_accountNumber_hide: "",
            Card_accountNumber_display: "",
            Card_accountNumber_show: false,
            Card_expirationMonth: "",
            Card_expirationYear: "",
            CCV: "",
            formValidators: {
                BillTo_firstName: null,
                BillTo_lastName: null,
                BillTo_email: null,
                Card_accountNumber: null,
                Card_expirationMonth: null,
                Card_expirationYear: null,
                CVV: null
            }
        }
        this.isComponentMounted = false
    }

    async componentDidMount() {
        this.isComponentMounted = true
        if (this.isComponentMounted) {
            this.setState({
                BillTo_firstName: this.props.FirstName || this.props.UserName,
                BillTo_lastName: this.props.LastName,
                BillTo_email: this.props.Email
            })
        }
    }

    componentWillUnmount = () => {
        this.isComponentMounted = false
    }

    setCartType = (event) => {
        const value = event.target.value;
        let number = value.replace(/[-]/g, '');
        var re = new RegExp("^4");
        if (number.match(re) != null) {
            this.setState({ Card_cardType: '001' })
        }
        var re = new RegExp("^5[1-5]");
        if (number.match(re) != null) {
            this.setState({ Card_cardType: '002' })
        }
    }

    handleChange = async (event) => {
        const name = event.target.name
        const value = event.target.value
        this.setState({ [name]: value })
        await this.validateInputs(event.target)
    }

    validateInputs = async (input) => {
        const name = input.name
        const value = input.value
        const formValidators = this.state.formValidators;
        switch (name) {
            case "BillTo_firstName":
            case "BillTo_lastName":
            case "BillTo_email":
            case "Card_accountNumber":

            case "CVV":
                formValidators[name] = value.length <= 0 ? "Éste campo es obligatorio" : ""
                break;
            case "Card_expirationYear":
                formValidators[name] = value.length != 4 ? "El año debe contener 4 dígitos" : ""
                break;
            case "Card_expirationMonth":
                formValidators[name] = value.length != 2 ? "El mes debe contener 2 dígitos" : ""
                break;
        }
        await this.setState({ formValidators })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const inputs = document.forms['formulario-pago'].getElementsByTagName("input");
        const validationInputs = Object.keys(this.state.formValidators)
        for (let input of inputs) {
            if (validationInputs.includes(input.name)) {
                this.validateInputs(input);
            }
        }
        let failedInputs;
        failedInputs = Object.values(this.state.formValidators).find(value => {
            return value == null || value.length > 0
        })

        if (failedInputs === undefined) {
            //Validar bin
            this.validateBinNumber(this.state.Card_accountNumber.substring(0, 4))
                .then(function (data) {
                    if (data.valid) {
                        document.getElementById('formulario-pago').submit();
                    } else {
                        swal({ text: "Esta tarjeta no es valida para este pago ", icon: "error" })
                    }
                });
        }
    }

    handleChangeCard = async (e) => {
        let valueDisplay = this.getValueDisplay(e, 5, 14)
        this.setCartType(e);
        this.handleChange(e);
        this.setState({ Card_accountNumber_hide: valueDisplay })
        this.setState({ Card_accountNumber_display: this.state.Card_accountNumber_show ? e.target.value : valueDisplay })
    }

    handleChangeCheck = (e) => {
        this.setState({ [e.target.name]: e.target.checked })
        this.setState({ Card_accountNumber_display: e.target.checked ? this.state.Card_accountNumber : this.state.Card_accountNumber_hide })
    }

    render() {
        const { formValidators } = this.state;
        return (
            <form action={this.props.UrlCyberSource} method="POST" id="formulario-pago">
                <input type="text" className="hidden" value={this.props.hostOrigin} name="hostOrigin" readOnly></input>
                <input type="text" className="hidden" value={this.props.OrderId} name="OrderId" readOnly></input>
                <input type="text" className="hidden" value={this.state.Card_cardType} name="Card_cardType" readOnly></input>
                <section className="wrapper-banorte">
                    <div className="data-side">
                        <div className="data-content">
                            <h1>Datos de compra</h1>
                            <label>Nombre</label>
                            <input type="text"
                                className={`${formValidators.BillTo_firstName && 'error'}`} id="BillTo_firstName"
                                name="BillTo_firstName"
                                value={this.state.BillTo_firstName}
                                onChange={this.handleChange} />
                            {(formValidators.BillTo_firstName) && (
                                <small>{formValidators.BillTo_firstName}</small>
                            )}
                            <label>Apellido</label>
                            <input type="text"
                                className={`${formValidators.BillTo_lastName && 'error'}`}
                                id="BillTo_lastName"
                                name="BillTo_lastName"
                                value={this.state.BillTo_lastName}
                                onChange={this.handleChange} />
                            {(formValidators.BillTo_lastName) && (
                                <small>{formValidators.BillTo_lastName}</small>
                            )}
                            <label>Correo eléctronico</label>
                            <input type="text"
                                className={`${formValidators.BillTo_email && 'error'}`}
                                id="BillTo_email"
                                name="BillTo_email"
                                value={this.state.BillTo_email}
                                readOnly />
                            <label>Número de pedido</label>
                            <p className="data-p">{this.props.OrderNumber}</p>
                            <label>Monto</label>
                            <p className="amount-p">{this.props.currency}{(this.props.Amount).toLocaleString(this.props.cultureCode, { minimumFractionDigits: 0, maximumFractionDigits: 2 })} {this.props.isoCurrency}</p>
                        </div>
                        <div className="cancel-button-content">
                            <button type="button" onClick={this.cancel}>Cancelar</button>
                        </div>
                    </div>
                    <div className="payment-side">
                        <div className="payment-content">
                            <h1>Pago con tarjeta</h1>
                            <p>Número de tarjeta</p>
                            <InputMask
                                className={`payment-card ${formValidators.Card_accountNumber && 'error'}`}
                                placeholder="XXXXXXXXXXXXXXXX"
                                mask="9999999999999999"
                                name="Card_accountNumber"
                                size="20"
                                onChange={this.handleChangeCard}
                                maskChar="" />
                            <InputMask
                                className="payment-card-display"
                                name="Card_accountNumber_display"
                                value={this.state.Card_accountNumber_display} />
                            {(formValidators.Card_accountNumber) && (
                                <small>{formValidators.Card_accountNumber}</small>
                            )}
                            <input type="checkbox" name="Card_accountNumber_show" id="Card_accountNumber_Show" onChange={this.handleChangeCheck} /><label htmlFor="Card_accountNumber_Show" className="cardShow-label"> Mostrar tarjeta</label>
                            <p>Fecha de expiración</p>
                            <InputMask
                                className={`payment-date ${formValidators.Card_expirationMonth && 'error'}`}
                                placeholder="MM"
                                mask="99"
                                name="Card_expirationMonth"
                                maskChar=""
                                onChange={this.handleChange} />
                            <InputMask
                                className={`payment-date ${formValidators.Card_expirationYear && 'error'}`}
                                placeholder="AAAA"
                                mask="9999"
                                name="Card_expirationYear"
                                maskChar=""
                                onChange={this.handleChange} />
                            {(formValidators.Card_expirationMonth) && (
                                <small>{formValidators.Card_expirationMonth}</small>
                            )}
                            {(formValidators.Card_expirationYear) && (
                                <small>{formValidators.Card_expirationYear}</small>
                            )}
                            <p>Código de seguridad</p>
                            <InputMask
                                className={`payment-code ${formValidators.CVV && 'error'}`}
                                mask="999"
                                name="CVV"
                                maskChar=""
                                onChange={this.handleChange} />
                            <span><i className="fa fa-credit-card card-i"></i></span>
                            <span className="card-text">3 digitos al reverso de la trajeta</span>
                            {(formValidators.CVV) && (
                                <small>{formValidators.CVV}</small>
                            )}
                            <button onClick={this.handleSubmit}>Pagar <strong>{this.props.currency}{(this.props.Amount).toLocaleString(this.props.cultureCode, { minimumFractionDigits: 0, maximumFractionDigits: 2 })} {this.props.isoCurrency}</strong></button>
                        </div>
                        <div className="payment-img-content">
                            <img src="./imgs/paymentMethod/visa-and-mastercard-logo.png" alt="VisaMastercard" />
                        </div>
                    </div>
                    <div className="information-side">
                        <div className="information-content">
                            <label className="information-title">Su información está protegida</label>
                            <p className="information-text">Empleamos los más altos estándares de seguridad (SSL, 3D Secure) para proteger su información personal y la de tu tarjeta. Revise al pie de su página el símbolo del condado (SSL) que garantiza la autenticidad de la página.</p>
                        </div>
                    </div>
                </section>
            </form>
        )
    }
}