import React, { Component } from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import Moment from 'react-moment'
import '../components/shoppingHistoryWrapper/shoppingHistoryWrapper.scss'
import '../components/purchasedItem/purchasedItem.scss'

class PurchaseOrderDetail extends Component {
    static displayName = PurchaseOrderDetail.name;
    constructor(props) {
        super(props);
        this.state = {
            purchaseOrderDetail: ""
        }
    }

    ps = (OrderId) => {
        window.setTimeout(function () {
            fetch("/api/PurchaseOrder/Detail?id=" + OrderId + "&isSale=true", {
                method: 'GET'
            })
                .then(response => response.json())
                .then(result => {
                    console.log("result: ", result);
                    this.setState({ purchaseOrderDetail: result });
                })
                .catch(error => console.log('error', error));
        }.bind(this), 2000);
    }

    componentWillMount() {
        this.ps(this.props.match.params.id);
    }

    componentDidMount() {
        $('html, body').scrollTop(0);
    }

    getProductOrder = () => {
        return (this.state.purchaseOrderDetail.purchaseOrderDetail.map(item =>
            <tr key={item.ProductId} className="row text-center justify-content-around">
                <td className="col">
                    <p className="p_info">{item.Quantity}</p>
                </td>
                <td className="col">
                    <p className="p_info">
                        {item.ProductName}
                    </p>
                    <p className="p_info">
                        <span className="item-code">
                            {item.ProductCode}
                        </span>
                    </p>
                </td>
                <td className="col" style={{ "white-space": "pre-wrap" }}>
                    <p className="p_info">
                        {item.VoucherCode}
                    </p>
                </td>
                <td className="col">
                    <p className="p_info">
                        {this.props.countrySetting.currency}{(item.ProductPrice).toLocaleString(this.props.countrySetting.cultureCode, { minimumFractionDigits: 0, maximumFractionDigits: 2 })}
                    </p>
                </td>
                <td className="col" id="D548195">
                    <p className="p_info">
                        {this.props.countrySetting.currency}{(item.SubTotal).toLocaleString(this.props.countrySetting.cultureCode, { minimumFractionDigits: 0, maximumFractionDigits: 2 })}
                    </p>
                </td>
            </tr>
        ));
    }

    showGenerateInvoice = () => {
        return this.state.purchaseOrderDetail.IsBillable ? <a href={"/create-invoice/" + this.state.purchaseOrderDetail.OrderNumber} className="btn">Generar Factura</a> : ""
    }

    render() {
        console.log("this.state: ", this.state);
        const style = {
            bgProviders: { background: 'url(./imgs/bgsections.png)' }
        }

        return (
            < div className="wrapper_shoppingHistory" >
                <div className="bg_sections" style={style.bgProviders}></div>

                {this.state.purchaseOrderDetail !== "" ?
                    <div className="contain_shop_history">
                        <h2 className="ttls_sections_shop">Detalle de compra</h2>
                        <div className="boxes_hop_history">
                            <h5 className="fw300">Orden No: {this.state.purchaseOrderDetail.OrderNumber}</h5>
                            <h6 className="fw300">{this.state.purchaseOrderDetail.IsSaleText}</h6>
                            <div className="table-wrap" style={{ "margin-top": "25px", "margin-bottom": "0.1rem", "background": "#f7fafc" }}>
                                <h3>Status : {this.state.purchaseOrderDetail.Status} &emsp; Fecha : <Moment format="DD/MM/YYYY">{this.state.purchaseOrderDetail.Date}</Moment></h3>
                            </div>
                            <div className="wrapper_purchased_item">
                                <table className="head_purchased_item" style={{ "width": "90%" }}>
                                    <thead>
                                        <tr className="row text-center justify-content-around">
                                            <th className="col"><h6 className="ttl_info">Cantidad</h6></th>
                                            <th className="col"><h6 className="ttl_info">Descripción</h6></th>
                                            <th className="col"><h6 className="ttl_info">Folio(s)</h6></th>
                                            <th className="col"><h6 className="ttl_info">Precio</h6></th>
                                            <th className="col"><h6 className="ttl_info">Subtotal</h6></th>
                                        </tr>
                                    </thead>
                                    <tbody>{this.getProductOrder()}</tbody>
                                    <tfoot>
                                        <tr className="row text-center justify-content-around">
                                            <th className="col"></th>
                                            <th className="col"></th>
                                            <th className="col"></th>
                                            <th className="col"><h6 className="ttl_info">Total</h6></th>
                                            <th id="Ctotal" className="col"><p className="paid">{this.props.countrySetting.currency}{(Number(this.state.purchaseOrderDetail.Total)).toLocaleString(this.props.countrySetting.cultureCode, { minimumFractionDigits: 0, maximumFractionDigits: 2 })}</p></th>
                                        </tr>
                                    </tfoot>
                                </table>

                            </div>
                            <div className="text-center">
                                <br />
                                <a href="/home" className="btn">Inicio</a>
                                &emsp;
                                <a href="/shopping-history" className="btn">Historial de Compra</a>
                                &emsp;
                                {this.showGenerateInvoice()}
                            </div>
                        </div>
                    </div>
                    : ""}</div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        countrySetting: state.countrySetting
    }
}

export default connect(mapStateToProps)(PurchaseOrderDetail);