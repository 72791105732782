import React from 'react';
import ItemPayMethod from './itemPayMethod'
import './boxPayMethod.scss'
import { useDispatch, useSelector } from "react-redux";
import { setModal } from '../../redux/actions';

const BoxPayMethod = ({ onCloseClick }) => {

    const dispatch = useDispatch();
    const paymentMethods = useSelector(state => state.user.paymentMethods);
    const isAuthenticated = useSelector(state => state.user.isAuthenticated);

    return (
        <div className="box_paymethod">
            {
                isAuthenticated ?
                    paymentMethods.map(payment => {
                        return <ItemPayMethod onCloseClick={onCloseClick} payment={payment} />
                    })
                    :
                    <div className="text-center">
                        <p><small>!Por favor inicia sesión para completar tu compra!</small> </p>
                        <button
                            className="btn btn_border ml-2"
                            onClick={() => dispatch(setModal())}
                        >
                            Entrar</button>
                    </div>
            }
        </div>
    )
}

export default BoxPayMethod