import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

const initState = {
    user: {
        isAuthenticated: false,
        userName: "",
        paymentMethods: []
    },
    modal: false,
    company: "",
    companyLogo: "",
    countrySetting: {
        cultureCode: "",
        isoCurrency: "",
        currency: ""
    },
    blocking: false
};

const generalReducer = (state = initState, action) => {
    switch (action.type) {
        case "Get_User":
            return {
                ...state,
                user: action.user
            };
        case "Set_Modal":
            return {
                ...state,
                modal: !state.modal
            };
        case "Set_Company":
            return {
                ...state,
                company: action.company
            };
        case "Set_CompanyLogo":
            return {
                ...state,
                companyLogo: action.companyLogo != null ? action.companyLogo : ""
            };
        case "Set_CountrySetting":
            return {
                ...state,
                countrySetting: action.countrySetting
            };
        case "Toggle_Blocking":
            return {
                ...state,
                blocking: !state.blocking
            };
        default:
            return state;
    }
}

export default createStore(generalReducer, applyMiddleware(thunk));;