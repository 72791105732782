import React from 'react'
import HubItemProvider from "../hubItemProvider";

const HubContainer = ({ providers }) => {

    return (
        <div className="wrapper_hub_container">
            <div className="mb-4 ttls_hub">
                <h4 className="ttls fw600">
                    <span className="ico_hub">
                        <img src="./imgs/ico_bag.png" alt="ICO" />
                    </span>
                    ¿Qué quieres hacer hoy?</h4>
                <p className="font-italic fw300">Entra a tus marcas favoritas y sorpréndete.</p>
            </div>

            <HubItemProvider providers={providers} />
        </div>
    )
}

export default HubContainer;