import React, { useState } from 'react'
import './itemProduct.scss'
import { Modal } from 'reactstrap';
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux';

const ItemProduct = (props) => {
    const countrySetting = useSelector(state => state.countrySetting);

    const [modal, setModal] = useState(false);
    const toggle = () => {
        if (props.settingProviderId > 0) {
            setModal(!modal);
        } else {
            props.addPruchaseItem(props);
        }
    }

    const [activeBoxTooltip, setBoxTooltip] = useState('')
    function ShowTooltip() {
        setBoxTooltip(activeBoxTooltip === '' ? ' is-show' : '')

    }

    const [newRef, setNewRef] = useState(false);
    const toggleRef = () => {

        if (!newRef) savedReferences();
        setNewRef(!newRef);
    }

    const { register, handleSubmit } = useForm();
    const onSubmit = (data, e) => {
        props.Reference = data.Reference;
        props.SaveReference = data.SaveReference;
        props.addPruchaseItem(props);
        e.target.reset();
        toggle();
    }

    const [references, setReferences] = useState([]);
    const savedReferences = () => {
        fetch("/Products/SavedReferences?providerId=" + props.ProviderId, { method: 'GET' })
            .then(response => response.json())
            .then(result => setReferences(result))
            .catch(error => console.log('error', error));
    }

    const BoxReference = () => {
        return (
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="new_ref">
                    <span onClick={toggleRef}>{newRef === false ? "Ver lista" : "Nuevo"}</span>
                </div>
                <div className="row align-items-center">
                    <div className="col-sm-8">
                        <div class="form-group m-0">
                            <label for="">{props.settingProviderName}</label>
                            {newRef === false ?
                                <input type="text" class="form-control" id="" name="Reference" required ref={register} autocomplete="off" />
                                :
                                <select className="custom-select custom-select-sm-x" name="Reference" required ref={register} >
                                    {
                                        references.map(item => {
                                            return <option value={item}>{item}</option>
                                        })
                                    }
                                </select>
                            }
                        </div>
                        {newRef === false ?
                            <div class="custom-control custom-checkbox mt-1">
                                <input type="checkbox" class="custom-control-input" id="saveref" name="SaveReference" ref={register} />
                                <label class="custom-control-label" for="saveref">
                                    <small>Guardar este {props.settingProviderName}</small>
                                </label>
                            </div>
                            : ""
                        }
                    </div>
                    <div className="col-sm-4 boxtop">
                        <button class="btn w-100">Agregar</button>
                    </div>

                </div>
            </form>
        )
    }

    return (
        <div className="col-6 col-sm-4 col-lg-3 wrapper_item_product">
            <div className="content_item_product">
                <div className="box_img_product">
                    <img src={props.urlRoot + props.UrlImage} alt="" />
                </div>
                <div className="box_ttls_item">
                    <h6 className="ttl_item_products">{props.Name}</h6>
                    <span
                        className="btn_info_items trans"
                        onClick={ShowTooltip}
                    >
                        <i class="fas fa-info-circle"></i></span>
                </div>
                <div className="box_info_product">
                    <div className="box_price_product">
                        <p className="label_x m-0">Por solo</p>
                        <p className="price_product m-0">{countrySetting.currency}{(Number(props.ProductPrice)).toLocaleString(countrySetting.cultureCode, { minimumFractionDigits: 0, maximumFractionDigits: 2 })}</p>
                    </div>
                    <div className="box_btn_add">
                        <button className="btn_add_prod trans" onClick={toggle}>
                            <i class="fas fa-shopping-cart"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div className={`content_tooltip ${activeBoxTooltip}`}>
                <span
                    className="btnclose_info"
                    onClick={ShowTooltip}
                >
                    <i class="fas fa-times-circle"></i>
                </span>
                {props.Restrictions}
            </div>
            <Modal isOpen={modal} toggle={toggle} className='wrapper_data'>
                <span className="btn_close_modal trans" onClick={toggle}><i className="fas fa-times-circle"></i> Cerrar</span>
                <div className="box_content_data">
                    <div className="box_info_data">
                        <div>
                            <span className="bg_logo_shop">
                                <img src={props.urlLogoClrs} alt="" />
                            </span>
                        </div>
                        <div className="ttl_info_data">
                            <h5 className="mb-1">{props.Name}</h5>
                            <p className="fw300 m-0 text-black-50">{props.providerName}</p>
                        </div>
                    </div>
                    <div className=" pt-2 pb-2">

                        <BoxReference />

                    </div>
                </div>
            </Modal>

        </div>
    )
}

export default ItemProduct;