import React from 'react'
import { Link } from 'react-router-dom';
import './bannerPromoHome.scss';
import { useSelector, useDispatch } from 'react-redux';
import { setModal } from '../../redux/actions';

const BannerPromoHome = () => {
    const dispatch = useDispatch();
    const isAuthenticated = useSelector(state => state.user.isAuthenticated);

    const validateSetModal = () => {
        if (!isAuthenticated) {
            dispatch(setModal());
        }
    }
    return (
        <div className="wrapper_hub_container">
            <div className="mb-4 ttls_hub">
                <h4 className="ttls fw600">
                    <span className="ico_hub">
                        <img src="./imgs/ico_exclamation.png" alt="ICO" />
                    </span>
                    Novedades</h4>
                <p className="font-italic fw300">Descubre las últimas noticias de la plataforma con más beneficios.</p>
            </div>

            <div className="bannerPromoHome">
                <Link to={isAuthenticated ? "/provider?id=2" : ""} onClick={validateSetModal}>
                    <img src="./imgs/banner_cinepolis.png" alt="Banner" />
                </Link>
            </div>
        </div>
    )
}

export default BannerPromoHome;