import React, { Component } from 'react';
import Providers  from '../components/providers'
import $ from 'jquery'

export class Provider extends Component {
    static displayName = Provider.name;

    componentDidMount() {
        $('html, body').scrollTop(0);
    }

    render() {
        return (
            <section className="container_provider">
                <Providers providerId={this.props.location.search} />
            </section>
        );
    }
}
