import React, { Component } from 'react';
import HubContainer from '../components/hubContainer';
import ItemPromoHome from '../components/itemPromoHome';
import BannerPromoHome from '../components/bannerPromoHome';
import WrapperProviderIndividual from '../components/wrapperProviderIndividual';
import WrapperBullets from '../components/wrapperBullets';
import SliderHome from '../components/sliderHome';
import $ from 'jquery';
import { connect } from 'react-redux';
import { setCompany, setModal } from '../redux/actions';

class Home extends Component {
    static displayName = Home.name;
    constructor(props) {
        super(props);
        this.state = {
            providers: [],
            carrousel: [],
            purchaseOrderDetail: {}
        }
    }

    model = () => {
        fetch("/api/Home/Hub?company=" + this.props.match.params.company, { method: 'GET' })
            .then(response => response.json())
            .then(result => this.setState({ providers: result.Providers, carrousel: result.Carrousel }))
            .catch(error => {
                console.log('error', error);
            });
    }
    
    componentWillMount() {
        this.model();
        this.props.setCompany(this.props.match.params.company);

        if (this.props.match.params.company == "totalplay") {
            this.props.setModal();
        }
    }

    componentDidMount() {
        $('html, body').scrollTop(0);
    }

    componentWillReceiveProps = (nextProps) => {
        this.model();
    }

    render() {
        return (
            <section>
                <div className="wrapper_welcome">
                    <SliderHome carrousel={this.state.carrousel} />
                </div>

                <div className="container">
                    <HubContainer providers={this.state.providers} />
                    {/*<ItemPromoHome />*/}
                    <BannerPromoHome />
                    <WrapperProviderIndividual />
                    <WrapperBullets />
                </div>

            </section>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.user.isAuthenticated
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setCompany: (company) => {
            dispatch(setCompany(company))
        },
        setModal: () => {
            dispatch(setModal())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);