import React, { Component } from "react";
import "./payUCash.scss";

export class PayUCashForm extends Component {
    render() {
        return (
            <section className="payucash-section">
                <h1>Felicidades</h1>
                <p className="payucash-p">Estas muy cerca de tener tus folios.</p>
                <p>Te hemos enviado un email, con las instrucciones para que realices tu pago en Baloto.</p>
                <img src="./imgs/paymentMethod/payu-cash-logo.png" alt="PayU Cash" />
                <i class="fas fa-check-circle fa-4x"></i>
                <p><a href="/"> Regresar al inicio</a></p>
            </section>
        )
    }
}