import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import { setModal } from '../../redux/actions';
import './hubItemProvider.scss'

class HubItemProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            providersOptions: props.providers
        }
    }

    componentWillReceiveProps(next_props) {
        this.setState({ providersOptions: next_props.providers });
    }

    validateSetModal = () => {
        if (!this.props.isAuthenticated) {
            this.props.setModal();
        }
    }


    validateItems = (length) => {
        switch (length) {
            case 4:
                return "four";
            case 3:
                return "three";
            case 2:
                return "two";
            case 1:
                return "one";
            default:
                return "";
        }
    }

    render() {
        const { providersOptions } = this.state;
        return providersOptions.map(list => {
            const className = this.validateItems(list.length);
            return <div className={`box_container_hub ${className}`} >
                {
                    list.map(providersOption => {
                        const bg_provider = { backgroundImage: providersOption.bgImage };
                        return (
                            <Link to={(providersOption.IsAvailable && this.props.isAuthenticated) ? "/provider?id=" + providersOption.id : ""}
                                className="item_provaider_hub"
                                key={providersOption.id}
                                style={bg_provider}
                                onClick={this.validateSetModal}
                            >
                                <p className="item_content">
                                    <img src={providersOption.IsAvailable ? providersOption.providerLogo : providersOption.ImageNotAvailable} alt="" />
                                </p>
                            </Link>
                        )
                    })
                }
            </div>
        })
    }
}

const mapStateToProps = (state) => {
    return { isAuthenticated: state.user.isAuthenticated }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setModal: () => {
            dispatch(setModal())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HubItemProvider)