import React, { Component } from "react";
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import './sliderHome.scss';
import { connect } from 'react-redux';
import { setModal } from '../../redux/actions';

class SliderHome extends Component {
    static displayName = SliderHome.name;
    constructor(props) {
        super(props)
        this.state = {
            slider: props.carrousel
        }
    }

    componentWillReceiveProps(next_props) {
        this.setState({ slider: next_props.carrousel });
    }

    validateSetModal = () => {
        if (!this.props.isAuthenticated) {
            this.props.setModal();
        }
    }

    render() {
        const settings = {
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            speed: 900,
            autoplaySpeed: 3500,
            pauseOnHover: true,
            swipeToSlide: true
        };

        return (
            <div className="wrapper_slider_home">
                <Slider {...settings}>
                    {
                        this.state.slider.map(item => {
                            return <div>
                                <Link to={this.props.isAuthenticated ? item.Link : ""} onClick={this.validateSetModal}>
                                    <img className="sld_desktop" src={item.UrlImage} alt="" />
                                    <img className="sld_mobile" src={item.UrlImageResponsive} alt="" />
                                </Link>
                            </div>
                        })
                    }
                </ Slider>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return { isAuthenticated: state.user.isAuthenticated }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setModal: () => {
            dispatch(setModal())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SliderHome)