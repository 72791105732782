import { Component } from 'react'

export class BasePaymentMethod extends Component {
    cancel = () => {
        window.location.href = "javascript:history.go(-1)";
    }

    getValueDisplay = (e, start, end) => {
        let valueDisplay = ""
        for (let l = 0; l < e.target.value.length; l++) {
            let val = e.target.value.substring(l, l + 1)
            if (l > start && l < end) {
                valueDisplay += val == " " ? val : "X";
            } else {
                valueDisplay += val;
            }
        }
        return valueDisplay
    }

    async validateBinNumber(bin) {
        return await fetch("/api/PaymentMethod/ValidateBinNumber?bin=" + bin)
            .then(function (response) {
                if (response.ok) {
                    return response.json()
                }
            }).catch(function (error) {
                return false
            })
    }
}